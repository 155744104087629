import React from "react";
import { ChevronRightIcon, TrophyIcon } from "@heroicons/react/24/solid";

const WinnerTakesAllChallengeCard = ({ challenge }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 max-w-sm flex flex-col matchallenge-card">
      <div className="flex items-center gap-4 mb-4">
        <div className="w-20 h-20 rounded-full shadow border-solid border-2 border-gray-100 px-4 py-3 flex justify-center items-center">
          <TrophyIcon className="w-12 h-12 text-school" />
        </div>
        <div>
          <h1 className="caption-1 text-gray-700">Donation {challenge.type}</h1>
          <h2 className="header-2 my-1">{challenge.title}</h2>
        </div>
      </div>
      <div className="my-6 w-full">
        {challenge.description && (
          <p className="caption-2">{challenge.description}</p>
        )}
      </div>
      {challenge.pending_wta || challenge.winner ? (
        <div
          className={`flex items-start winner-takes-all-challenge-details winner-takes-all-challenge-${challenge.pending_wta ? "pending" : "won"}`}
        >
          <div className="w-full rounded-sm px-2 py-3 text-center border border-primary-blue border-solid">
            <div className="caption-3 text-primary-blue">
              Challenge Completed!
            </div>
            <span className="caption-4">Winner:&nbsp;</span>
            <span className="caption-3 winner-takes-all-challenge-winner-display">
              {challenge.winner_text}
            </span>
            <div>
              <span className="caption-4">Award:&nbsp;</span>
              <span className="caption-3.winner-takes-all-challenge-award-display">
                {challenge.award}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex items-start winner-takes-all-challenge-details">
            <div className="w-3/5 items-start border-solid border-r border-gray-300">
              <div className="text-center">
                <p className="caption-4">Group with the</p>
                <span className="caption-1 winner-takes-all-challenge-most-goal-type">
                  Most {challenge.match_type}s
                </span>
                <p>
                  <span className="caption-4">by&nbsp;</span>
                  <span className="caption-3">{challenge.end_date}<br/>{challenge.end_time}</span>
                </p>
              </div>
            </div>
            <div className="w-2/5">
              <div className="text-center">
                <p className="caption-4">
                  will receive
                </p>
                <p className="caption-1 winner-takes-all-challenge-will-receive">
                  {challenge.award}
                </p>
              </div>
            </div>
          </div>
          <div className="my-6">
            <div className="winner-takes-all-challenge-leaderboard">
              <div className="grid grid-cols-4 gap-2 bg-gray-100 p-3 rounded-md">
                <div className="col-span-3 flex justify-start caption-2 winner-takes-all-challenge-groups-header">
                  {challenge?.competitors_count > 5 ? "Top 5 " : ""}Competing Groups
                </div>
                <div className="col-span-1 flex-justify-end caption-2 winner-takes-all-challenge-type-header">
                  {challenge.match_type}s
                </div>
                {challenge.competitors.map((competitor) => (
                  <React.Fragment key={competitor.name.toLowerCase().replace(" ", "_")}>
                    <div className="col-span-3 flex justify-start caption-3 challenge-competitor">
                      {competitor.name}
                    </div>
                    <div className="col-span-1 flex justify-end caption-4 challenge-statistic">
                      {competitor.statistic}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="winner-takes-all-challenge-footer">
            <p className="text-sm text-gray-700">
              For <span className="caption-2">{challenge.competitors_count}</span> Competing Groups
            </p>
            <p className="text-sm text-gray-700">
              Offered by <span className="caption-3">{challenge.donor_name}</span>
            </p>
          </div>
        </div>
      )}

      {!challenge.completed && (
        <div className="flex justify-end w-full mt-6">
          <a
            href={challenge.donate_url}
            className="text-school font-bold text-sm flex items-center gap-1 hover:text-gray-800"
          >
            <span>Contribute</span>
            <ChevronRightIcon className="w-4 h-4" />
          </a>
        </div>
      )}
    </div>
  );
};

export default WinnerTakesAllChallengeCard;
